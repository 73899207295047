
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';
import { Config } from '../services/config';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  careersContent: any ;
  lang = 'en';


  constructor(
              private readonly contentsService: ContentService,  
              public translation: TranslationService,
              private title: Title, private meta: Meta,
              private readonly config: Config) { }

  ngOnInit(): void {
    this.getCarrersContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getCarrersContent();
    });

  }

  getCarrersContent = () => {
    this.contentsService.getPage('careers').subscribe(res => {
      this.careersContent = res.data;
      this.title.setTitle(this.careersContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.careersContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.careersContent?.metaTitle});
    });
  }


  downloadFile(pdfId) {
    const url = this.config.buildUrl(`api/assets/shiftr/${pdfId}`);
    window.open(url, '_blank')
  }
}
