import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CookiesEventService {

  public event = new BehaviorSubject<boolean>(true);

  constructor(private cookieService: CookieService, @Inject(DOCUMENT) private dom) {
    if (this.cookieService.check('_ga') || this.cookieService.check('_pk_id.2.1fff')) {
      this.setEvent(false);
    }
  }

  // tslint:disable-next-line:typedef
  setEvent(changeToggle: boolean) {
    this.event.next(changeToggle);
  }

  // tslint:disable-next-line:typedef
  updateCanonicalUrl(url: string){
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element === null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  // tslint:disable-next-line:typedef
  createAlternateUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector(`link[rel='alternate']`) || null;
    if (element === null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'alternate');
    element.setAttribute('href', url);
    element.setAttribute('hreflang', 'en-us');
  }

  // tslint:disable-next-line:typedef
  getEvent() {
    return this.event;
  }



}
