<section class="banner">
    <div class="inner-banner">
      <div class="container">
        <div class="row pt-5 pt-lg-0">
            <div class="col-md-6 order-md-2 pt-4 pt-md-0 align-self-center">
                <div class="banner-img">
                    <img class="img-fluid" alt="Banner Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +erpContent?.banner[0].img1[0]}}">
                </div>
            </div>
            <div class="col-md-6 align-self-center"> 
              <div class="banner-info">
                <div class="title mb-3" [innerHtml]="erpContent?.banner[0].heading"></div>
                <p class="pb-3">{{ erpContent?.banner[0].description }}</p>
                <a routerLink="/contact" class="btn btn-success">{{ erpContent?.banner[0].buttonTitle }}</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container pb-4">
    <div class="row mb-3">
        <div class="col-sm-8 col-lg-6 offset-sm-2 offset-lg-3">
            <div class="text-center">
                <h4 class="mb-3 fontweight600">{{ erpContent?.onestopHeading[0].heading }}</h4>
                <p>{{ erpContent?.onestopHeading[0].description }}</p>
            </div>
        </div>
    </div>

     <div class="row mb-5">
        <div class="col-md-6 px-0">
            <div class="h-100">
                <div class="col-6 px-0">
                    <div class="management-col">
                        <div class="d-flex justify-content-between">
                            <h6>{{ erpContent?.openstop1[0].heading }}</h6>
                            <span> {{ erpContent?.openstop1[0].num }} </span>
                        </div>
                        <p>{{ erpContent?.openstop1[0].description }}</p>
                    </div>
                </div>
                <div class="col-6 offset-6 px-0">
                    <div class="management-col">
                        <div class="d-flex justify-content-between">
                            <h6>{{ erpContent?.openstop2[0].heading }}</h6>
                            <span> {{ erpContent?.openstop2[0].num }} </span>
                        </div>
                        <p>{{ erpContent?.openstop2[0].description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 px-0">
            <div class="h-100">
                <div class="col-6 px-0">
                    <div class="management-col">
                        <div class="d-flex justify-content-between">
                            <h6>{{ erpContent?.openstop3[0].heading }}</h6>
                            <span> {{ erpContent?.openstop3[0].num }} </span>
                        </div>
                        <p>{{ erpContent?.openstop3[0].description }}</p>
                    </div>
                </div>
                <div class="col-6 offset-6 px-0">
                    <div class="management-col">
                        <div class="d-flex justify-content-between">
                            <h6>{{ erpContent?.openstop4[0].heading }}</h6>
                            <span> {{ erpContent?.openstop4[0].num }} </span>
                        </div>
                        <p>{{ erpContent?.openstop4[0].description }}</p>
                    </div>
                </div>
            </div>
        </div> 
    </div>  
   
  </section>

  <section class="potential-section">
    <div class="container">
        <div class="row pb-2">
            <div class="col-sm-8 col-lg-6 offset-sm-2 offset-lg-3">
                <div class="text-center px-lg-5">
                    <h4 class="mb-3 fontweight600 px-1"> {{ erpContent?.headingList[0].heading }} </h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="potential-row text-center clearfix">
                    
                    <div class="col-lg-6 offset-lg-3 d-none d-lg-block px-5 px-xl-0 text-center"><img src="assets/images/erp/erp2.png" class="img-fluid"></div>
                        
                    <div class="potential-col" [ngClass]="'potential'+ i" *ngFor="let item of erpContent?.businessList; let i = index">
                        <h6 class="textprimary"> {{ item.heading }} </h6>
                        <p> {{ item.description }} </p>
                    </div>

                        
                </div>
            </div>
        </div>
    </div>
  </section>


  <section class="divers-section">
    <div class="divers-section-inner">
        <div class="container text-center">
            <h4 class="mb-4">{{ erpContent?.headingList[1].heading }}</h4>
            <div class="d-flex flex-wrap justify-content-center">

                <div class="diverse-col" *ngFor="let item of erpContent?.diverseList">
                    <div class="diverse-item"><img [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]" alt="Icon"></div>
                    <h6> {{ item.heading }} </h6>
                </div>

            </div>
        </div>
    </div>
  </section>


  <section class="erp-section">
    <div class="erp-section-bg">
        <div class="container py-4 py-sm-5">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="erp-info">
                        <h4>{{ erpContent?.contactBluetheme[0].heading }}</h4>
                        <p class="mb-0">{{ erpContent?.contactBluetheme[0].description }}</p>
                    </div>
                </div>
                <div class="col-sm-5 offset-sm-1 align-self-center">
                    <a routerLink="/contact" class="btn btn-success"> {{ erpContent?.contactBluetheme[0].buttonTitle }} </a>
                </div>
            </div>
        </div>
    </div>
  </section>