import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  termsContent: any ;
  lang = 'en';

  constructor(private readonly contentsService: ContentService,  public translation: TranslationService,
              private title: Title, private meta: Meta) { }


  ngOnInit(): void {
    this.getTremsContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getTremsContent();
    });
  }

  getTremsContent = () => {
    this.contentsService.getPage('terms').subscribe(res => {
      this.termsContent = res.data;
      this.title.setTitle(this.termsContent?.metaTitle);
      this.meta.updateTag({name: 'description', content: this.termsContent?.metaDescription});
      this.meta.updateTag({name: 'title', content: this.termsContent?.metaTitle});
    });
  }

}
