import { Component, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  thankyouContent: any;
  lang = 'en';

  constructor(private readonly contentsService: ContentService, public translation: TranslationService) { }

  ngOnInit(): void {
    this.getThankyouContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getThankyouContent();
    });
  }

  getThankyouContent = () => {
    this.contentsService.getPage('thankyou').subscribe(res => {
      this.thankyouContent = res.data;
    });
  }


}
