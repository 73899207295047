import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-wms',
  templateUrl: './wms.component.html',
  styleUrls: ['./wms.component.scss']
})
export class WmsComponent implements OnInit {

  wmsContent: any ;
  lang = 'en';

  constructor(private readonly contentsService: ContentService,  public translation: TranslationService,
              private title: Title, private meta: Meta) { }


  ngOnInit(): void {

    this.getWmsContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getWmsContent();
    });

  }

  getWmsContent = () => {
    this.contentsService.getPage('wms').subscribe(res => {
      this.wmsContent = res.data;
      this.title.setTitle(this.wmsContent?.metaTitle);
      this.meta.updateTag({name: 'description', content: this.wmsContent?.metaDescription});
      this.meta.updateTag({name: 'title', content: this.wmsContent?.metaTitle});
    });
  }

}
