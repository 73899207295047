import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { PPSComponent } from './pps/pps.component';
import { DmsComponent } from './dms/dms.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImprintComponent } from './imprint/imprint.component';
import { ErpComponent } from './erp/erp.component';
import { WmsComponent } from './wms/wms.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyComponent } from './privacy/privacy.component';

import { Config, DefaultConfig } from 'src/app/services/config';
import { AuthInterceptor } from 'src/app/services/auth.interceptor';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CareersComponent } from './careers/careers.component';

import { CookiesComponent } from './cookies/cookies.component';
import { CookiesEventService } from './services/cookies-events/cookies-event.service';
import { CookieService } from 'ngx-cookie-service';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SolutionsComponent,
    PPSComponent,
    DmsComponent,
    PPSComponent,
    TermsAndConditionsComponent,
    ImprintComponent,
    ErpComponent,
    WmsComponent,
    ContactusComponent,
    AboutusComponent,
    PrivacyComponent,
    ThankyouComponent,
    CareersComponent,
    CookiesComponent,
    CareersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
    CookiesEventService,
    CookieService,
    { provide: Config, useValue: DefaultConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
