<section class="banner">
    <div class="inner-banner">
      <div class="container">
        <div class="row pt-5 pt-lg-0">
            <div class="col-md-6 order-md-2 pt-4 pt-md-0 align-self-center">
                <div class="banner-img">
                    <img class="img-fluid" alt="Banner Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +wmsContent?.banner[0].img1[0]}}">
                </div>
            </div>
            <div class="col-md-6 align-self-center"> 
              <div class="banner-info">
                <div class="title mb-3" [innerHtml]="wmsContent?.banner[0].heading"></div>
                <p class="pb-3">{{ wmsContent?.banner[0].description }}</p>
                <a routerLink="/contact" class="btn btn-success">{{ wmsContent?.banner[0].buttonTitle }}</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>

  <section class="overcome-section">
      <div class="container">
        <div class="row mb-2">
            <div class="col-sm-8 col-lg-6 offset-sm-2 offset-lg-3">
                <div class="text-center">
                    <h4 class="mb-3 fontweight600">{{ wmsContent?.overcomeHeading[0].heading }}</h4>
                    <p>{{ wmsContent?.overcomeHeading[0].description }}</p>
                </div>
            </div>
        </div>

        <div class="row overcome-item-row">
            <div class="col-md-4 overcome-item mb-4 mb-md-0" *ngFor="let item of wmsContent?.overcomeList">
                <h5>{{ item.heading }}</h5>
                <span><img [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]" alt="Icon" class="img-fluid"></span>
                <p>{{ item.description }}</p>
            </div>
        </div>
      </div>
  </section>


<section class="satisfaction-section">
    <div class="satisfaction-inner pb-lg-5">
        <div class="container">
            <div class="row mb-3">
                <div class="col-sm-8 offset-sm-2">
                    <div class="text-center px-lg-5">
                        <h4 class="mb-3 fontweight600">{{ wmsContent?.reusltHeading[0].heading }}</h4>
                        <p>{{ wmsContent?.reusltHeading[0].description }}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-lg-3 mb-4 mb-lg-0" *ngFor="let item of wmsContent?.resultList">
                    <div class="satisfaction border">
                        <div class="satisfaction-head d-flex">
                            <img [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]" alt="Icon">
                            <h6 class="align-self-center mb-0 align-self-center">{{ item.heading }}</h6>
                        </div>
                        <p class="mb-0" [innerHtml]="item.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="erp-section">
    <div class="erp-section-bg">
        <div class="container py-4 py-sm-5">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="erp-info">
                        <h4>{{ wmsContent?.contactBluetheme[0].heading }}</h4>
                        <p class="mb-0">{{ wmsContent?.contactBluetheme[0].description }}</p>
                    </div>
                </div>
                <div class="col-sm-5 offset-sm-1 align-self-center">
                    <a routerLink="/contact" class="btn btn-success"> {{ wmsContent?.contactBluetheme[0].buttonTitle }} </a>
                </div>
            </div>
        </div>
    </div>
  </section>
