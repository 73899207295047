
<section class="banner">
    <div class="container pt-4">
        <div class="row">
            <div class="col-6 col-lg-3 align-self-end pb-4 mb-2">
                <img class="img-fluid" alt="Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +aboutContent?.banner[0].img1[0]}}">
            </div>
            <div class="col-6 col-lg-3 align-self-end pb-4 mb-2">
                <img class="img-fluid" alt="Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +aboutContent?.banner[0].img2[0]}}">
            </div>
            <div class="col-lg-6 pb-4 mb-2">
                <div>
                    <h1 class="fontsize38 pb-2">{{ aboutContent?.banner[0].heading }}</h1>
                    <p [innerHtml]="aboutContent?.banner[0].description"></p>
                </div>
            </div>
            <div class="col-6 col-lg-6 align-self-end pb-4 mb-2">
                <img class="img-fluid" alt="Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +aboutContent?.banner[0].img3[0]}}">
            </div>
            <div class="col-6 col-lg-6 align-self-end pb-4 mb-2">
                <img class="img-fluid" alt="Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +aboutContent?.banner[0].img4[0]}}">
            </div>
        </div>
    </div>
</section>


<section class="container pb-4">
    <div class="row">
        <div class="col-md-6">
            <h6 class="textprimary border-bottom pb-3 mb-4">{{ aboutContent?.visionContent[0].subHeading }}</h6>
            <h5 class="mb-3">{{ aboutContent?.visionContent[0].heading }}</h5>
            <p [innerHtml]="aboutContent?.visionContent[0].description"></p>
        </div>
        <div class="col-md-6">
            <div class="ourvision-item">
                <div class="ourvision-info" *ngFor="let item of aboutContent?.visionList">
                    <span>{{ item.num }}</span>
                    <p>{{ item.description }}</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="teamsection">
    <div class="container">
        <h6 class="textprimary mb-3 text-center text-lg-left">{{ aboutContent?.teamHeading[0].subHeading }}</h6>
        <h4 class="pb-4 text-center text-lg-left">{{ aboutContent?.teamHeading[0].heading }}</h4>
        <div class="row">
            <div class="col-sm-6 col-lg-4 col-xl-3" *ngFor="let item of aboutContent?.teamlist">
                  <div class="flip-card mx-auto mx-lg-0">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <img [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]" alt="Team Image" class="img-fluid">
                        </div>
                        <div class="flip-card-back text-left">
                            <h6 class="first-caps">{{item.subheading}}</h6>
                            <p>{{item.info}}</p>
                        </div>
                    </div>
                    <div class="text-center pt-4">
                        <h6>{{ item.heading }}</h6>
                        <span>{{ item.description }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="col-12 text-center pt-lg-4">
    <a routerLink="/careers" class="btn btn-success">{{ aboutContent?.buttonTitle }}</a>
</div>



