import { Component, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

  solutionsContent: any ;
  lang = 'en';

  constructor(
              private readonly contentsService: ContentService,  
              public translation: TranslationService,
              private title: Title, private meta: Meta) { }

  ngOnInit(): void {

      this.translation.getLangValue().subscribe(item => {
        this.lang = item;
        this.getSolutionContent();
      });

  }

  getSolutionContent = () => {
    this.contentsService.getPage('solution').subscribe(res => {
      this.solutionsContent = res.data;
      this.title.setTitle(this.solutionsContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.solutionsContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.solutionsContent?.metaTitle});
    });
  }

}
