<section class="banner">
    <div class="inner-banner">
      <div class="container">
        <div class="row pt-5 pt-lg-0">
            <div class="col-md-6 order-md-2 pt-4 pt-md-0 align-self-center">
                <div class="banner-img">
                    <img class="img-fluid" alt="Banner Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +dmsContent?.banner[0].img1[0]}}">
                </div>
            </div>
            <div class="col-md-6 align-self-center"> 
              <div class="banner-info">
                <div class="title mb-3" [innerHtml]="dmsContent?.banner[0].heading"></div>
                <p class="pb-3">{{ dmsContent?.banner[0].description }}</p>
                <a routerLink="/contact" class="btn btn-success">{{ dmsContent?.banner[0].buttonTitle }}</a>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container pb-3">
    <div class="row">
      <div class="col-md-8 col-lg-6 offset-md-2 offset-lg-3">
        <div class="text-center">  
            <h4 class="mb-3 fontweight600">{{ dmsContent?.headingDms[0].heading }}</h4>
            <p>{{ dmsContent?.headingDms[0].description }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="cloud-section">
      <div class="container">
        <div class="row cloud-row">

            <div class="col-md-4 cloud-col" *ngFor="let item of dmsContent?.dmsList">
                <div class="cloud-col-info">
                    <div class="media mb-3">
                        <img alt="Icon" class="img-fluid" [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]">
                        <div class="media-body align-self-center pl-3">
                          <h5 class="mt-0 mb-0">{{ item.heading }}</h5>
                        </div>
                    </div>
                    <p>{{ item.description }}</p>
                </div>
            </div>

        </div>
       
      </div>
  </section>


  <section class="collaborate-section">
      <div class="container">
          <div class="row">
              <div class="col-md-7">
                  <img class="img-fluid" alt="Monitor Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +dmsContent?.collaborateImg[0]}}">
              </div>
              <div class="col-md-5">
                  <ul class="list-unstyled pl-4">
                      <li *ngFor="let item of dmsContent?.collaborateList">
                          <div class="collaborate-col">
                            <h6 class="fontweight600">{{item.heading}}</h6>
                            <p class="mb-0">{{item.description}}</p>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </section>


 
  <section class="erp-section">
    <div class="erp-section-bg">
        <div class="container py-4 py-sm-5">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="erp-info">
                        <h4>{{ dmsContent?.contactBluetheme[0].heading }}</h4>
                        <p class="mb-0">{{ dmsContent?.contactBluetheme[0].description }}</p>
                    </div>
                </div>
                <div class="col-sm-5 offset-sm-1 align-self-center">
                    <a routerLink="/contact" class="btn btn-success"> {{ dmsContent?.contactBluetheme[0].buttonTitle }} </a>
                </div>
            </div>
        </div>
    </div>
  </section>