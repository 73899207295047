import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  formContent: any;
  lang = 'en';

  contactForm: FormGroup;
  submitted = false;
  formSuccess = false;

  // tslint:disable-next-line:max-line-length
  constructor(private formBuilder: FormBuilder, private readonly contentsService: ContentService, public translation: TranslationService,
              private title: Title, private meta: Meta) { }

  ngOnInit(): void {


    this.getFormContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getFormContent();
    });

    this.createForm();


  }

  getFormContent = () => {
    this.contentsService.getPage('contactinformation').subscribe(res => {
      this.formContent = res.data;
      this.title.setTitle(this.formContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.formContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.formContent?.metaTitle});
    });
  }


  createForm(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
     // phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{15}$')]],
      subject: ['', Validators.required]
   });
  }


   // tslint:disable-next-line:typedef
   onSubmit() {
       this.submitted = true;

       // stop here if form is invalid
       if (this.contactForm.invalid) {
           return;
       }

       const param = JSON.stringify(this.generateParam());
       this.contentsService.sendRequest('contactform', param).subscribe(item => {
         console.log(item);
         this.formSuccess = true;
       }, error => {
         console.log(error);
         this.formSuccess = false;
       });
   }

   generateParam = () => {
    return {
      firstname: {
        iv: this.contactForm.controls.firstName.value
      },
      lastname: {
        iv: this.contactForm.controls.lastName.value
      },
      email: {
        iv: this.contactForm.controls.email.value
      },
      contact: {
        iv: this.contactForm.controls.phone.value
      },
      message: {
        iv: this.contactForm.controls.subject.value
      },
    };
  }


  // tslint:disable-next-line:typedef
   get f() { return this.contactForm.controls; }


   // tslint:disable-next-line:typedef
   onlyNumberAllowed(event){

    const chareCode = (event.which) ? event.which : event.keyCode;

    if (chareCode > 31 && (chareCode < 48 || chareCode > 57)){
      console.log('chareCode restricted');
      return false;
    }
    return true;

   }


}
