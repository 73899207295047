import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-dms',
  templateUrl: './dms.component.html',
  styleUrls: ['./dms.component.scss']
})
export class DmsComponent implements OnInit {

  dmsContent: any ;
  lang = 'en';

  constructor(private readonly contentsService: ContentService,  public translation: TranslationService,
              private title: Title, private meta: Meta) { }


  ngOnInit(): void {

    this.getDmsContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getDmsContent();
    });

  }

  getDmsContent = () => {
    this.contentsService.getPage('dms').subscribe(res => {
      this.dmsContent = res.data;
      this.title.setTitle(this.dmsContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.dmsContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.dmsContent?.metaTitle});
    });
  }

}
