import { Component, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  lang = 'en';
  navContent: any;
  constructor(public translation: TranslationService, private readonly contentService: ContentService) { }

  ngOnInit(): void {

    this.getHeaderContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getHeaderContent();
    });
  }

  getHeaderContent = () => {
    this.contentService.getPage('navigation').subscribe(res => {
      this.navContent = res.data;
    });
  }


  setLang = (item) => {
    this.lang = item;
    this.translation.setValue(item);
  }


  // ASIDE NAVE OPEN OR CLOSE
  // tslint:disable-next-line:member-ordering
  navbarOpen = false;
  // tslint:disable-next-line:member-ordering
  navbarOverlar = false;
  // tslint:disable-next-line:typedef
  toggleNavbar() {
    this.navbarOpen = true;
    this.navbarOverlar = true;
  }
  // tslint:disable-next-line:typedef
  isClickMenu(){
    this.navbarOpen = !this.navbarOpen;
    this.navbarOverlar = !this.navbarOverlar;
  }

}
