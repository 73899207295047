import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-erp',
  templateUrl: './erp.component.html',
  styleUrls: ['./erp.component.scss']
})
export class ErpComponent implements OnInit {

  erpContent: any ;
  lang = 'en';

  constructor(private readonly contentsService: ContentService,  public translation: TranslationService,
              private title: Title, private meta: Meta) { }


  ngOnInit(): void {

    this.getErpContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getErpContent();
    });

  }

  getErpContent = () => {
    this.contentsService.getPage('erp').subscribe(res => {
      this.erpContent = res.data;
      this.title.setTitle(this.erpContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.erpContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.erpContent?.metaTitle});
    });
  }

}
