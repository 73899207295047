<footer>
    <div class="container pb-md-2">
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <div class="footer-col">
                    <h5 class="mb-4"> 
                        <a routerLink="/" ><img
                        src="{{
                          'https://squidex.it-blue.net/api/assets/shiftr/' +
                          footerContent?.adressItem[0].logo[0]
                        }}"
                        class="img-fluid" alt="Logo"/></a>
                    </h5>
                    <address>
                        <a class="tel" href="tel:+{{ footerContent?.adressItem[0].phone }}"><img src="assets/images/phone.png" alt="Phone Icon"> {{ footerContent?.adressItem[0].phone }} </a><br>
                        <a class="mail" href="mailto:+{{ footerContent?.adressItem[0].email }}"><img src="assets/images/email.png" alt="Email Icon">{{ footerContent?.adressItem[0].email }}</a>
                        <div class="d-flex">
                            <span class="map"><img src="assets/images/location.png" alt="Location Icon"></span> <div class="address-col"> {{ footerContent?.adressItem[0].adress }} </div></div>
                    </address>
                </div>
            </div>
            <div class="col-sm-6 col-md-4">
                <div class="footer-col">
                    <h5> {{ footerContent?.productItem[0].heading }} </h5>
                   <ul class="list-unstyled footer-list pt-1">
                       <li><a routerLinkActive="active" routerLink="/wms">{{ footerContent?.productItem[0].link1 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/pps">{{ footerContent?.productItem[0].link2 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/erp">{{ footerContent?.productItem[0].link4 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/dms">{{ footerContent?.productItem[0].link3 }}</a></li>
                   </ul>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="footer-col">
                    <h5>{{ footerContent?.companyDetails[0].heading }}</h5>
                   <ul class="list-unstyled footer-list pt-1">
                       <li><a routerLinkActive="active" routerLink="/about">{{ footerContent?.companyDetails[0].link1 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/contact">{{ footerContent?.companyDetails[0].link2 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/careers">{{ footerContent?.companyDetails[0].link3 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/terms">{{ footerContent?.companyDetails[0].link4 }}</a></li>
                       <li><a routerLinkActive="active" routerLink="/privacy">{{ footerContent?.companyDetails[0].link5 }}</a></li>
                   </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="border-top secondary-footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-4 col-md-4 pb-3 pb-sm-0 align-self-center">
                    <span>&#169; {{ footerContent?.secondaryFooter[0].item1 }}</span>
                </div>
                <div class="col-sm-8 col-md-4 offset-md-4">
                    <div class="d-flex">
                        <ul class="list-unstyled d-flex social">
                            <li><a href="javascript:void(0)"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="javascript:void(0)"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                        <ul class="list-unstyled d-flex mb-0 imprint-col">
                            <li><a href="#">|</a></li>
                            <li><a routerLinkActive="active" routerLink="/imprint">{{ footerContent?.secondaryFooter[0].item2 }}</a></li>
                            <li><a href="javascript:void(0)" (click)="cookieSetting()">{{ footerContent?.secondaryFooter[0].item3 }}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>