
<section class="terms-section">
  <div class="container pt-4">
    <h3 class="text-center mb-5"> {{ imprintContent?.headingSection }} </h3>

    <div class="address-section">
      <img class="img-fluid" alt="logo" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +imprintContent?.addressList[0].logo[0]}}">
      <div class="d-sm-flex pt-3">
        <address>
          {{ imprintContent?.addressList[0].address }}
        </address>
        <ul class="list-unstyled mb-0">
          <li><a href="tel:+{{imprintContent?.addressList[0].phone}}"> {{ imprintContent?.addressList[0].phone }}</a></li>
          <li><a href="mailto:+{{imprintContent?.addressList[0].email}}"> {{ imprintContent?.addressList[0].email }}</a></li>
        </ul>
      </div>
    </div>

    <div class="item pb-4" *ngFor="let item of imprintContent?.contentList">
      <h4 class="mb-3"> {{ item.heading }} </h4>
      <p [innerHtml]="item?.description"></p>
    </div>

      
  </div>
</section>
