
<section class="banner mt-4">
    <div class="container">
      <div class="row">
          <div class="col-md-6 order-md-2 pt-4 pt-md-0 mb-5 mb-md-0 align-self-center">
              <img class="img-fluid" alt="Digitalize Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +careersContent?.banner[0].img1[0]}}">
          </div>
          <div class="col-md-6 align-self-center"> 
            <div class="banner-info">
                <div class="title mb-3" [innerHtml]="careersContent?.banner[0].heading"></div>
                <p class="mb-0">{{ careersContent?.banner[0].description }}</p>
            </div>
          </div>
      </div>
    </div>
</section>

<section class="container">
    <div class="row">
    <div class="col-sm-8 col-lg-6 offset-sm-2 offset-lg-3 mb-2">
        <div class="text-center">
            <h3 class="mb-3 fontweight600">{{ careersContent?.openPositionHeading[0].heading }}</h3>
            <p>{{ careersContent?.openPositionHeading[0].description }}</p>
        </div>
    </div>
    </div>
</section>

<section class="article-section">
    <section class="container">

        <div class="row" *ngFor="let item of careersContent?.openPosition;">

            <div *ngIf="item; else show" class="col-12 mb-4">
                <div class="cardbox d-lg-flex align-item-center">
                    <div class="card-col">
                        <div class="mb-3 mb-lg-0 d-lg-flex flex-column align-item-center">
                            <h5>{{item?.position}}</h5>
                            <a class="mb-0 link-btn" (click)="downloadFile(item.pdf)" type='button' >
                                {{item?.buttonTitle}}
                                </a>
                        </div>
                    </div>
                    <div class="card-col px-lg-3">
                        <p class="mb-3 mb-lg-0">{{item.description}}</p>
                    </div>
                    <div class="card-col d-lg-flex align-item-center justify-content-lg-end">
                        <div class="mail-item text-lg-right">
                            {{item.resumeTest}}
                            <span class="d-block d-flex align-item-center justify-content-lg-end"><i class="bi bi-envelope mr-2"></i><a href="mailto:info@shiftr-ag.ch">{{item.email}}</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #show>
                <div class="col-12 text-center empty-job">
                    <img class="img-fluid" alt="Empty image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +careersContent?.emtyJob[0].img1[0]}}">
                    <div class="text-center pt-4">
                        <p class="mb-0">{{careersContent?.emtyJob[0].text1}}</p>
                        <span>{{careersContent?.emtyJob[0].text2}} <a href="mailto:info@shiftr-ag.ch">{{careersContent?.emtyJob[0].email}}</a></span> 
                    </div>
                </div>
        </ng-template>



    </section>
</section>

<section class="erp-section">
    <div class="erp-section-bg">
        <div class="container py-4 py-sm-5">
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <div class="erp-info">
                        <h4>{{ careersContent?.contactBluetheme[0].heading }}</h4>
                        <p class="mb-0">{{ careersContent?.contactBluetheme[0].description }}</p>
                    </div>
                </div>
                <div class="col-sm-5 offset-sm-1 align-self-center">
                    <a routerLink="/contact" class="btn btn-success"> {{ careersContent?.contactBluetheme[0].buttonTitle }} </a>
                </div>
            </div>
        </div>
    </div>
</section>
