<nav class="navbar navbar-expand-lg navbar-light py-lg-3">
  <div class="container">
      <a class="navbar-brand" routerLink="/solution">
        <img
        src="{{
          'https://squidex.it-blue.net/api/assets/shiftr/' +
          navContent?.menuList[0].logo[0]
        }}"
        class="img-fluid" alt="Logo"/>
      </a>
      
      <button (click)="toggleNavbar()" class="navbar-toggler" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>
  
     
      <div class="navbar-overlay" *ngIf="navbarOverlar" (click)="isClickMenu()"></div>
      <div class="collapse navbar-collapse" (click)="isClickMenu()" [ngClass]="{ 'show': navbarOpen }">
        <div class="menu-close-icon text-right px-4 d-lg-none"><i class="bi bi-x-circle"></i></div>
        <ul class="navbar-nav ml-auto">
              <li class="nav-item dropdown">
                <a class="nav-link" routerLinkActive="active" routerLink="/solution">
                  {{ navContent?.menuList[0].link1 }}
                </a>
                <div class="dropdown-menu shadow-sm text-uppercase">
                    <a class="dropdown-item" routerLinkActive="active" routerLink="/wms">{{ navContent?.subMenu[0].link1 }}</a>
                    <a class="dropdown-item" routerLinkActive="active" routerLink="/pps">{{ navContent?.subMenu[0].link2 }}</a>
                    <a class="dropdown-item" routerLinkActive="active" routerLink="/erp">{{ navContent?.subMenu[0].link3 }}</a>
                    <a class="dropdown-item" routerLinkActive="active" routerLink="/dms">{{ navContent?.subMenu[0].link4 }}</a>
                </div>
              </li>

              <li class="nav-item">
                  <a class="nav-link"  routerLinkActive="active" routerLink="/contact"> {{ navContent?.menuList[0].link2 }} </a>
              </li>

              <li class="nav-item">
                  <a class="nav-link" routerLinkActive="active" routerLink="/about"> {{ navContent?.menuList[0].link3 }} </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/careers"> {{ navContent?.menuList[0].link4 }} </a>
            </li>
          </ul>
          <ul class="navbar-nav">
            <!-- <li class="nav-item">
              <a class="nav-link">
                <button routerLink="/contact" type="button" class="btn btn-outline-primary ml-lg-0">{{ navContent?.menuList[0].buttonTitle }}</button>
              </a>
            </li> -->
            <li class="nav-item align-self-lg-center" *ngIf="lang === 'de'">
              <a (click)="setLang('en')" class="nav-link fontweight600" type="button">EN</a>
            </li>
            <li class="nav-item align-self-lg-center fontweight600" *ngIf="lang === 'en'">
              <a (click)="setLang('de')" class="nav-link" type="button">DE</a>
            </li>
        </ul>
      </div>
  </div>
</nav>