
<div class="container pt-4" *ngIf="!formSuccess">
    <div class="row mx-0 contect-row">
        <div class="col-md-5 px-0 overflow-hidden">
            <div class="forminfo px-4">
                <h5 class="mb-3">{{ formContent?.headingSection[0].heading }}</h5>
                <p class="pr-lg-5">{{ formContent?.headingSection[0].description }}</p>
               
                <address class="pt-4 mb-0">
                    <a class="tel" href="tel:+41 44 872 10 21"><img src="assets/images/contact/phone.png" alt="Phone Icon">{{ formContent?.adresslist[0].phone }}</a><br>
                    <a class="mail" href="mailto:info@shiftr-ag.ch"><img src="assets/images/contact/email.png" alt="Email Icon">{{ formContent?.adresslist[0].email }}</a>
                    <div class="d-flex">
                        <span class="map"><img src="assets/images/contact/location.png" alt="Location Icon"></span> <div class="address-col">{{ formContent?.adresslist[0].address }}</div></div>
                </address>
                <div class="text-right">
                  <img src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +formContent?.contactImage[0]}}" class="img-fluid bottomimage" alt="image">
                </div>
            </div>
        </div>
        <div class="col-md-7 align-self-center">
            <div class="px-3 py-4">
                <form class="customeform-section" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="fname">First name</label>
                        <input type="text" placeholder="First name" formControlName="firstName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="lname">Last name</label>
                        <input type="text" placeholder="Last name" formControlName="lastName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="email1">Email</label>
                          <input type="text" placeholder="Email" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                          <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required">Email is required</div>
                              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label for="cnumber">Contact number</label>
                          <input type="text" placeholder="Phone" (keypress)="onlyNumberAllowed($event)" formControlName="phone" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                          <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                              <div *ngIf="f.phone.errors.required">This field is required</div>
                          </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="message1">Message</label>
                        <textarea placeholder="Message" rows="4" formControlName="subject" class="form-control h-auto" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }"></textarea>
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                            <div *ngIf="f.subject.errors.required">Message is required</div>
                        </div>
                    </div>
                   
                   <div class="text-right pt-4">
                        <button type="submit" class="btn btn-success">{{ formContent?.buttonTitle }}</button>
                   </div>
                </form>
            </div>
        </div>
    </div>


</div>


<app-thankyou *ngIf="formSuccess"></app-thankyou>