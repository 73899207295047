import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolutionsComponent } from './solutions/solutions.component';
import { PPSComponent } from './pps/pps.component';
import { DmsComponent } from './dms/dms.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ImprintComponent } from './imprint/imprint.component';
import { ErpComponent } from './erp/erp.component';
import { WmsComponent } from './wms/wms.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CareersComponent } from './careers/careers.component';

import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
    { path: '',   redirectTo: '/solution', pathMatch: 'full' },
    { path: 'solution', component: SolutionsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'pps', component: PPSComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'dms', component: DmsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'erp', component: ErpComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'wms', component: WmsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'contact', component: ContactusComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'about', component: AboutusComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'careers', component: CareersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'privacy', component: PrivacyComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'terms', component: TermsAndConditionsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'imprint', component: ImprintComponent, pathMatch: 'full', canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
