
<section class="banner">
  <div class="inner-banner">
    <div class="container">
      <div class="row pt-5 pt-lg-0">
          <div class="col-md-6 order-md-2 pt-4 pt-md-0 align-self-center">
              <div class="banner-img">
                  <img class="img-fluid" alt="Banner Image" src="{{'https://squidex.it-blue.net/api/assets/shiftr/' +ppsContent?.banner[0].img1[0]}}">
              </div>
          </div>
          <div class="col-md-6 align-self-center"> 
            <div class="banner-info">
              <div class="title mb-3" [innerHtml]="ppsContent?.banner[0].heading"></div>
              <p class="pb-3">{{ ppsContent?.banner[0].description }}</p>
              <a routerLink="/contact" class="btn btn-success">{{ ppsContent?.banner[0].buttonTitle }}</a>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>

<section class="container pb-3">
  <div class="row">
    <div class="col-sm-8 col-lg-6 offset-sm-2 offset-lg-3">
        <div class="text-center">
          <h4 class="mb-3 fontweight600">{{ ppsContent?.headingSection[0].heading }}</h4>
          <p>{{ ppsContent?.headingSection[0].description }}</p>
        </div>
    </div>
  </div>
</section>

<section class="article-section">
  <section class="container">

    <div class="row" *ngFor="let item of ppsContent?.ppsList; let i = index">
      <div class="col-12">

        <div class="row mb-5 mb-lg-0" *ngIf="i % 2 == 0">
          <div class="col-md-6 text-center">
            <img class="img-fluid" alt="Image" [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]">
          </div>
          <div class="col-md-6 align-self-center">
              <div class="article-info">
                  <h4 class="mb-4">{{ item.heading }}</h4>
                  <p>{{ item.description }}</p>
                </div>
          </div>
        </div>

        <div class="row mb-5 mb-lg-0" *ngIf="i % 2 !== 0">
          <div class="col-md-6 align-self-center">
              <div class="article-info">
                  <h4 class="mb-4">{{ item.heading }}</h4>
                  <p>{{ item.description }}</p>
              </div>
          </div>
          <div class="col-md-6 text-center">
            <img class="img-fluid" alt="Image" [src]="'https://squidex.it-blue.net/api/assets/shiftr/'+item?.img1[0]">
          </div>
        </div>

      </div>
    </div>


  </section>
</section>


<section class="erp-section">
  <div class="erp-section-bg">
      <div class="container py-4 py-sm-5">
          <div class="row">
              <div class="col-sm-6 mb-3">
                  <div class="erp-info">
                      <h4>{{ ppsContent?.contactBluetheme[0].heading }}</h4>
                      <p class="mb-0">{{ ppsContent?.contactBluetheme[0].description }}</p>
                  </div>
              </div>
              <div class="col-sm-5 offset-sm-1 align-self-center">
                  <a routerLink="/contact" class="btn btn-success"> {{ ppsContent?.contactBluetheme[0].buttonTitle }} </a>
              </div>
          </div>
      </div>
  </div>
</section>