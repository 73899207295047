
<section class="terms-section">
  <div class="container pt-4">
    <h3 class="text-center mb-5"> {{ termsContent?.headingSection}} </h3>

    <div class="item pb-4" *ngFor="let item of termsContent?.contentList">
      <h4 class="mb-3"> {{ item.heading }} </h4>
      <p [innerHtml]="item?.description"></p>
    </div>
    
  </div>
</section>
