<div
  *ngIf="cookieMailbox"
  class="d-block"
  id="cookies-main"
  class="cookies-sec"
>
  <div class="container">
    <div class="row">
      <div class="col-12 cookies-box py-3 px-md-4">
        <div class="mb-3 px-md-2">
          <div class="cookies-logo-row media mb-3 mb-lg-4">
            <img
              src="../../assets/images/logo.png"
              alt="Cookie Logo"
              class="mr-3 mr-md-5 img-fluid"
              alt="Cookie Settings Logo"
            />
            <div class="media-body align-self-center">
              <span>We use cookies.</span> Further information can be found in
              our <a [routerLink]="['/privacy']"> privacy policy.</a>
              <a type="button" (click)="changeCookieSelection(false)" style="float: right; font-size: 20px;" class="text-danger"><i class="fa fa-times-circle"></i></a>
            </div>
          </div>

          <div id="cookie_content" class="cookie-content jqcard_row">
            <div class="row jqcard-box">
              <div class="col-lg-12 jqcard mb-2 mb-lg-0" >
                <div class="card h-100">
                  <div class="card-body">
                    <h5
                      class="card-title d-flex justify-content-between align-items-center"
                    >
                      <div class="form-group form-check">
                        <input
                          disabled
                          type="checkbox"
                          checked="checked"
                          class="form-check-input jqcheck"
                          id="necessary_check"
                        />
                        <label class="form-check-label" for="necessary_check"
                          >Necessary <span>{{necessaryCookieList.length}}</span></label
                        >
                      </div>
                      <i></i>
                    </h5>
                    <p (click)="showTools()" class="card-text para pt-2 pt-lg-0 mb-2">
                      Necessary cookies make a website usable by enabling basic
                      functions like page navigation. The website cannot
                      function properly without these cookies.
                    </p>
                    <div class="table-responsive-xl res-table tbl_necessary">
                      <table class="table cookie-table">
                        <thead>
                          <tr>
                            <th></th>
                            <th width="130px">Cookie Name</th>
                            <th>Provider</th>
                            <th>Purpose</th>
                            <th width="120px">Expiry</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let cookieJson of necessaryCookieList">
                            <td class="pr-0">
                              <div class="form-group form-check">
                                <input
                                  type="checkbox"
                                  disabled
                                  value="true"
                                  id="checkid"
                                  checked="checked"
                                  class="form-check-input"
                                />
                              </div>
                            </td>
                            <td class="pl-0">{{cookieJson.CookieName}}</td>
                            <td>{{cookieJson.Provider}}</td>
                            <td>
                              <p class="showmorecontent-width d-inline-block">
                                {{cookieJson.Purpose}}
                              </p>
                              <!-- <app-read-more class="showlesscontent-width d-inline-block" [text]="cookieJson.Purpose" maxLength="5"></app-read-more> -->
                              <!-- <span class="jqreadMore readMore"
                                >..Read More</span
                              > -->
                            </td>
                            <td>{{cookieJson.Expiry}}</td>
                            <td>{{cookieJson.Type}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p class="pt-3">
              We include cookies which are necessary for the operation of the
              website and for the display of personalized content. You can
              change your consent anytime from the <b>Cookies</b> link at the
              bottom of any page of this website.
            </p>
          </div>
        </div>
        <div class="cookies-button-row d-flex justify-content-center mb-lg-2">
          <button id="change_cookie" (click)="changeCookieSelection(true)" class="btn btn1">
            See Necessary Cookie
          </button>
          <!-- <button (click)="cookiesEventService.setEvent(false)" id="accept_cookie_selected" class="btn btn2">
            Accept selected cookies
          </button>
          <button id="acceptall_cookie"  (click)="acceptAllCookie()" class="btn btn3">
            Accept all and continue
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
