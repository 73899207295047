import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ContentService } from '../services/content.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  aboutContent: any ;
  lang = 'en';

  constructor(private readonly contentsService: ContentService,  public translation: TranslationService,
              private title: Title, private meta: Meta) { }

  ngOnInit(): void {

    this.getAboutContent();
    this.translation.getLangValue().subscribe(item => {
      this.lang = item;
      this.getAboutContent();
    });

  }


  getAboutContent = () => {
    this.contentsService.getPage('about').subscribe(res => {
      this.aboutContent = res.data;
      this.title.setTitle(this.aboutContent?.metaTitle);
      this.meta.updateTag({name: 'description',  content: this.aboutContent?.metaDescription});
      this.meta.updateTag({name: 'title',  content: this.aboutContent?.metaTitle});
    });
  }
}
