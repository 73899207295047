import { Component, OnInit } from '@angular/core';
import { ContentService } from '../services/content.service';
import { CookiesEventService } from '../services/cookies-events/cookies-event.service';
import { TranslationService } from '../services/translation/translation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerContent: any;
  lang = 'en';

  constructor(private readonly contentService: ContentService,
              public translation: TranslationService,
              private cookiesEventService: CookiesEventService) { }

  ngOnInit(): void {

   this.getFooterContent();
   this.translation.getLangValue().subscribe(item => {
    this.lang = item;
    this.getFooterContent();
    });

  }

  getFooterContent = () => {
    this.contentService.getPage('footer').subscribe(res => {
      this.footerContent = res.data;
    });
  }

  // tslint:disable-next-line:typedef
  cookieSetting(){
    this.cookiesEventService.setEvent(true);
  }

}
