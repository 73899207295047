import { Renderer2, Component, Inject, OnInit } from '@angular/core';
import { CookiesEventService } from 'src/app/services/cookies-events/cookies-event.service';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';

declare var $: any;


@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {


  cookieMailbox = false;
  google = false;
  matomo = false;
  bothStatistics = false;
  necessaryCookieList: any = [];
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private doc: any,
    public cookiesEventService: CookiesEventService,
    private cookieService: CookieService,
    private httpClient: HttpClient
  ) {
    this.cookiesEventService.getEvent().subscribe((x) => {
      this.cookieMailbox = x;
    });
  }

  ngOnInit(): void {
    this.getNecessaryCookie();
    // console.log(this.cookieMailbox);
  } // ngOnInit end here

  // tslint:disable-next-line:typedef
  getNecessaryCookie() {
    this.httpClient.get('assets/jsondata/NecessaryCookie.json').subscribe((res) => {
      this.necessaryCookieList = res;
    });
  }

  changeCookieSelection = (showTool) => {
    if (showTool) {
      $('#change_cookie').hide();
      $('#cookie_content').show();
      $('#accept_cookie_selected').show();
    } else {
      $('#change_cookie').hide();
      $('#cookie_content').hide();
      $('#accept_cookie_selected').hide();
      this.cookiesEventService.setEvent(false);
    }
  }

  showTools = () => {
    $('.jqcard_row .jqcard').on('click', function() {
      const elm = $(this);
      const elmSize = $(this).length;
      const elmP = $('.para');

      $(this)
        .addClass('activeCustomecard')
        .siblings('.jqcard')
        .removeClass('activeCustomecard');
      $('.res-table').hide();
      $('.jqcard').addClass('customecard px-2').removeClass('col-lg-6');

      if (elmSize === 1) {
        elm.find('.res-table').show();
        elmP.hide();
        elm.find(elmP).show();
      }
    });
  }

}
